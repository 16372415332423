import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import ArticlesGrid from "../components/articles-grid"
import Seo from "../components/seo"
import SgHeadings from "../components/sg-headings"
import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

const BlogIndex = () => {
  const { allStrapiArticle, blogBg } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: {fields: createdDate, order: DESC})
      {
        nodes {
          ...ArticleCard
        }
      }
      blogBg: file(relativePath: {eq: "images/about.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const background = getImage(blogBg)
  const shortDesc = "Making tours and activites famous. Aimed at travel agencies, tour operators, transport companies and hotels who want to sell travel experiences to travellers on location"
  const headline = "Toristy PR, Editorials and Technical Articles"

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Online travel solutions for experiences" }} />
      <div className="relative w-full h-full bg-gradient-to-r from-black to-cyan-700">
            <BgImage 
              image={background} 
              alt="A view of Finnish Northern Lights reflected off a lake" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <SgHeadings title={headline} description={shortDesc} />
                </div>  
          </div>
      <main className="pb-6">
        <ArticlesGrid articles={allStrapiArticle.nodes} />
      </main>
    </Layout>
  )
}

export default BlogIndex
